import useSWR from "swr";
import useSWRImmutable from "swr/immutable";
import { swrUserNamespace, useRediaPlatformContext } from "../../../rediaPlatform/RediaPlatformProvider";
import { BranchInformation, Patron } from "@libry-content/redia-platform";

export interface PatronWithBranchInformation {
  user: Patron;
  branch?: BranchInformation;
}

const useBranch = (branchCode: string | undefined) => {
  const { rediaPlatform } = useRediaPlatformContext();
  const swrCacheKey: [string, string] | null =
    branchCode && rediaPlatform ? ["rediaPlatform:branches", branchCode] : null;
  return useSWRImmutable(swrCacheKey, ([, branchCode]) => rediaPlatform?.getBranch(branchCode));
};

const useUserProfile = () => {
  const { user, rediaPlatform } = useRediaPlatformContext();
  const identifier = user?.identifiers?.[0]?.value;
  const swrCacheKey: [string, string, string] | null =
    user && rediaPlatform && identifier ? [swrUserNamespace, identifier, "profile"] : null;
  return useSWR(swrCacheKey, () => rediaPlatform?.refreshUserProfile());
};

export const usePatron = () => {
  const profile = useUserProfile();
  const branch = useBranch(profile.data?.pickupLocation.branchCode);
  const isLoading = profile.isLoading || branch.isLoading;
  const error = profile.error ?? branch.error;
  const data =
    !isLoading && profile.data
      ? <PatronWithBranchInformation>{
          user: profile.data,
          branch: branch.data,
        }
      : undefined;
      
  return { isLoading, error, data, mutate: profile.mutate };
};
